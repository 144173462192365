var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.list")) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container overflow-hidden"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "p-1",
    attrs: {
      "header-tag": "header",
      "role": "tab"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.accordion-1",
      modifiers: {
        "accordion-1": true
      }
    }],
    attrs: {
      "block": "",
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.$t("search2")))])], 1), _c('b-collapse', {
    attrs: {
      "id": "accordion-1",
      "visible": _vm.isSearch,
      "accordion": "my-accordion",
      "role": "tabpanel"
    }
  }, [_c('b-card-body', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("status")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    staticClass: "form-control",
    attrs: {
      "required": "",
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("all")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("in-process")))]), _c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("application-sent")))]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment-passed")))]), _c('option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v(_vm._s(_vm.$t("awaiting-witness-verification")))]), _c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment-completed")))])])])]), _c('div', {
    staticClass: "col-lg-4",
    staticStyle: {
      "margin-top": "auto",
      "margin-bottom": "0px"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.searchQuery
    }
  }, [_vm._v(_vm._s(_vm.$t("search")))])], 1)])])])], 1)], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-2"
  }, [_c('table', {
    staticClass: "table mb-0 table-center table-sm",
    staticStyle: {
      "font-size": "10pt"
    }
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', [_vm._v("#")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("amendment.ref-no")))]), _c('th', [_vm._v(_vm._s(_vm.$t("testator-name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("amendment.of")))]), _c('th', [_vm._v(_vm._s(_vm.$t("amendment.date-of-amendment")))]), _c('th', [_vm._v(_vm._s(_vm.$t("status")))]), _c('th', [_vm._v(_vm._s(_vm.$t("action")))])])]), _vm._l(_vm.amendment_array, function (pindaan, index) {
    return _c('tbody', {
      key: index
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(index))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(pindaan.id))]), _c('td', [_vm._v(" " + _vm._s(pindaan.amendmentable ? pindaan.amendmentable.user.name : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(pindaan.amendmentable ? pindaan.amendmentable.user.ic_number : "-") + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(pindaan.count ? pindaan.count : "-") + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm._f("getConvertDate")(pindaan.created_at)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.pindaanStatus(pindaan.status)) + " ")]), _c('td', [_c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [_c('button', {
      staticClass: "btn btn-secondary btn-sm",
      on: {
        "click": function ($event) {
          return _vm.senaraiPindaan(pindaan.amendmentable_id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("view")) + " ")]), pindaan.status == 5 && _vm.userRole == 'Staff' ? _c('button', {
      staticClass: "btn btn-secondary btn-sm",
      on: {
        "click": function ($event) {
          return _vm.wasiatCertificate(pindaan.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("print-cert")) + " ")]) : _vm._e()])])]), _vm.amendment_array && _vm.amendment_array.length == 0 ? _c('tr', [_c('td', {
      staticClass: "text-center",
      attrs: {
        "colspan": "9"
      }
    }, [_c('h6', {
      staticClass: "mt-5 mb-5"
    }, [_vm._v(_vm._s(_vm.$t("amendment.no-records")))])])]) : _vm._e()]);
  })], 2)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.totalRows,
      "per-page": _vm.pagination.perPage,
      "aria-controls": "my-table"
    },
    on: {
      "change": _vm.pageClicks
    },
    model: {
      value: _vm.pagination.currentPage,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "currentPage", $$v);
      },
      expression: "pagination.currentPage"
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form custom-form"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.pagination.perPage,
      expression: "pagination.perPage"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "Sortbylist-job"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.pagination, "perPage", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.changePerPage();
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 " + _vm._s(_vm.$t("per-page")) + " ")]), _c('option', {
    attrs: {
      "value": "25"
    }
  }, [_vm._v(" 25 " + _vm._s(_vm.$t("per-page")) + " ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50 " + _vm._s(_vm.$t("per-page")) + " ")])])])])])])])])])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }